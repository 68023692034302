import axios from "axios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import CommonValues from "../Common/Util";
import UserIcon from "./../assets/images/user.png";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import moment from "moment";
import validator from "validator";

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      NewImageValue:
        "https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg",
      ImageValue:
        "https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg",
      FirstName: "",
      LastName: "",
      Email: "",
      MobileNo: "",
      States: 0,
      Country: 0,
      CountryList: [],
      CountryId: 0,
      StateList: [],
      stateedit: true,
      StateId: 0,
      DOB: null,
      ToggleSideBar: true,
      ShowIdentity: false,
      ErrorMessage: "",
      EventDashboardValue: 4,
      sidebarvalue: 5,
      SuccessMessage: "",
      Loading: false,
      UserPhotoImage: false,
      displaySuccessMessage: false,
      buttonLabel: "Save",
      EventList: [],
      UserData: {},
      isImageUploaded: false,
      fileName: "",
      CountryArray: [],
      StateArray: [],
    };

    this.GetCountries();
    this.GetStatesByCountryId(this.props.UserData.Countrys.Id);
  }

  onCloseClick = (val) => {
    this.props.onCloseClick(val);
  };

  componentDidMount = () => {
    let ProfileImageURL = localStorage.getItem("ProfileImageURL");
    if (ProfileImageURL == "") {
      this.setState({
        NewImageValue: "https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg",
      });
    } else {
      let newProfileImageURL = JSON.parse(ProfileImageURL);
      this.setState({
        NewImageValue: newProfileImageURL,
      });
    }
    this.setState({
      FirstName: this.props.UserData.FirstName,
      LastName: this.props.UserData.LastName,
      Email: this.props.UserData.Email,
      MobileNo: this.props.UserData.MobileNo,
      ShowIdentity: this.props.UserData.ShowIdentity,
      DOB: this.props.UserData.DOB,
      CountryId: parseInt(this.props.UserData.CountryId),
      StateId: parseInt(this.props.UserData.StateId),
      CountryArray: this.props.UserData.Countrys,
      StateArray: this.props.UserData.States,
      Loading: false,
    });

  };

  onImageChange = (e) => {
    this.setState({
      fileName: e.target.files[0].name,
      NewImageValue: URL.createObjectURL(e.target.files[0]),
      ImageValue: e.target.files[0],
      isImageUploaded: true,
    });
  };
  handleCountryChange = (e) => {
    this.setState({
      StateList: [
        {
          Id: 0,
          ShortName: "NN",
          Name: "--Select the State--",
        },
      ],
    });
    this.GetStatesByCountryId(e.target.value);
    if (e.target.value == 0) {
    } else {
      this.setState({
        StateId: 0,
      });
      this.stateInput.focus();
    }
  };

  handleStateChange = (e) => {
    this.setState({
      StateId: e.target.value,
    });
  };
  onTagsChangeForCountry = (event, values) => {


    if (values != null) {
      this.setState(
        {
          CountryArray: values,
          StateArray: [],
        },
        () => {
          this.setState({
            StateList: [],
          });

          this.GetStatesByCountryId(this.state.CountryArray.Id);

          if (this.state.CountryArray.Id == 0) {
            this.setState({
              countryCss: 0,
            });
          } else {
            this.setState({
              CountryId: this.state.CountryArray.Id,
              stateedit: false,
              countryCss: 1,
              StateId: 0,
            });
          }
        }
      );
    } else {
      this.setState({
        CountryArray: [],
        StateList: [],
        StateArray: [],
        stateedit: true,

        CountryId: 0,
        StateId: 0,
      });
    }
  };
  onChangeDate = (date) => {
    this.setState({
      DOB: date,
    });
  };
  onTagsChangeForState = (event, values) => {
    if (values != null) {
      this.setState(
        {
          StateArray: values,
        },
        () => {
          if (this.state.StateArray.Id == 0) {
            this.setState({
              stateCss: 0,
            });
          } else {
            this.setState({
              stateCss: 1,
              StateId: this.state.StateArray.Id,
            });
          }
        }
      );
    } else {
      this.setState({
        StateArray: [],
        StateId: 0,
      });
    }
  };
  GetCountries = () => {
    var config = {
      method: "get",
      url: process.env.REACT_APP_EVENT_API + `Event/GetCountries`,
      headers: {},
    };

    axios(config)
      .then((response) => {
        response.data.forEach((element) => {
          this.state.CountryList.push(element);
        });
      })
      .catch((error) => {
        if (error.response.status == 401) {
          window.location.pathname = "/logout";
        } else {
          this.setState({
            ErrorMessage: "Error while get all countries",
            SuccessMessage: "",
          });
        }
      });
  };
  GetStatesByCountryId = (id) => {
    if (id == undefined || id == 0) {
      id = 0;
    }
    var config = {
      method: "get",
      url:
        process.env.REACT_APP_EVENT_API + `Event/GetStatesByCountryId?Id=${id}`,
      headers: {},
    };

    axios(config)
      .then((response) => {
        const obj = {
          Id: 0,

          Name: "--Select the State--",

          CountryId: 0,
        };

        const data = response.data;

        data.push(obj);

        this.setState({
          StateList: data,

          CountryId: id,
        });
      })

      .catch((error) => {
        this.setState({
          ErrorMessage: "Error while gettting event get states by countryid",
        });
        if (error.response.status == 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };
  onChange = (e) => {
    // this.setState({
    //   [e.target.name]: e.target.value,
    // });
    const regex =/^[a-zA-Z0-9_]+$/ ;
    if (e.target.value === "" || regex.test(e.target.value)) {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };
  handleKeypress = (e) => {
    if (e.key == "Enter") {
      this.onSaveClick();
    }
  };

  validate = () => {
    let valid = true;

    if (
      this.state.FirstName == "" ||
      this.state.FirstName == null ||
      this.state.FirstName == undefined
    ) {
      this.setState({
        ErrorMessage: "Please enter first name",
        SuccessMessage: "",
      });
      return false;
    }

  
    if (
      this.state.LastName == "" ||
      this.state.LastName == null ||
      this.state.LastName == undefined
    ) {
      this.setState({
        ErrorMessage: "Please enter last name",
        SuccessMessage: "",
      });
      return false;
    }

    if (this.state.MobileNo.length == 0) {
      this.setState({
        ErrorMessage: "Please enter mobile number",
        SuccessMessage: "",
      });
      return false;
    }
    if (this.state.MobileNo.length != 10) {
      this.setState({
        ErrorMessage: "Please enter the valid mobile number",
        SuccessMessage: "",
      });
      return false;
    }

    if (this.state.DOB == "" || this.state.DOB == null) {
      this.setState({
        ErrorMessage: "Please select DOB",
        SuccessMessage: "",
      });
      return false;
    }
    if (moment(this.state.today1).diff(moment(this.state.DOB), "years") < 14) {
      this.setState({
        ErrorMessage: "Age under 14 is not allowed.",
        SuccessMessage: "",
      });
      console.log();
      return false;
    }
    if (this.state.CountryId == 0) {
      this.setState({
        ErrorMessage: "Please select a Country",
        SuccessMessage: "",
      });
      return false;
    }
    if (this.state.StateId == 0) {
      this.setState({
        ErrorMessage: "Please select a state",
        SuccessMessage: "",
      });
      return false;
    }

    this.setState({ ErrorMessage: "" });
    return valid;
  };

  onSaveClick = () => {
    const newDate = moment(this.state.DOB).format("YYYY-MM-DD");
    if (this.validate()) {
      var data = JSON.stringify({
        FirstName: this.state.FirstName,
        LastName: this.state.LastName,
        ShowIdentity: this.state.ShowIdentity,
        MobileNo: this.state.MobileNo,
        DOB: newDate,
        CountryId: parseInt(this.state.CountryId),
        StateId: parseInt(this.state.StateId),
      });
      const token = CommonValues.GetAuth0Token().replace(/^"|"$/g, "");
      var config = {
        method: "put",
        url: process.env.REACT_APP_API + `User/UserInformationUpdate`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };
      this.setState({
        Loading: true,
        buttonLabel: "Saving",
      });
      axios(config)
        .then((response) => {
          if (this.state.isImageUploaded == true) {
            this.uploadImageToS3Bucket();
          } else {
            this.setState(
              {
                Loading: false,
                SuccessMessage: "Profile details updated successfully...!!!",
                displaySuccessMessage: true,
                ErrorMessage: "",
                buttonLabel: "Save",
              },
              () => {
                localStorage.setItem("UserName", this.state.FirstName);
              }
            );
          }
        })

        .catch((error) => {
          if (error.response.status == 401) {
            window.location.pathname = "/logout";
          } else {
            console.log(error);
          }
        });
    }
  };

  uploadImageToS3Bucket = () => {
    const token = CommonValues.GetAuth0Token().replace(/^"|"$/g, "");
    var FormData = require("form-data");
    var data = new FormData();
    data.append("FormFile", this.state.ImageValue);
    var config = {
      method: "put",
      url: `${process.env.REACT_APP_MEDIA_API}api/Event/UploadProfileImage`,
      headers: {
        ContentType: "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    this.setState({
      Loading: true,
      buttonLabel: "Saving",
    });

    axios(config)
      .then((response) => {
        this.onClick();
        this.setState({
          isImageUploaded: false,
        });
        this.props.onRefreshClick();
        this.setState({
          Loading: false,
          SuccessMessage: "Profile details updated successfully...!!!",
          displaySuccessMessage: true,
          ErrorMessage: "",
          buttonLabel: "Save",
        }, () => {
          localStorage.setItem("UserName", this.state.FirstName);
        });
      })
      .catch((error) => {
        if (error.response.status == 401) {
          window.location.pathname = "/logout";
        } else {
          this.setState({
            isImageUploaded: false,
          });
        }
      });
  };

  onCheckboxChange = () => {
    this.setState({
      ShowIdentity: !this.state.ShowIdentity,
    });
  };

  ContainsAny(str, items) {
    for (var i in items) {
      var item = items[i];
      if (str.indexOf(item) > -1) {
        this.setState({
          UserPhotoImage: false,
        });
        return true;
      } else {
        this.setState({
          UserPhotoImage: true,
        });
      }
    }
    return false;
  }

  onClick() {
    this.setState({
      displaySuccessMessage: true,
    });
  }

  onHide(val) {
    this.onCloseClick(val);
  }

  renderFooter() {
    return (
      <div>
        <Button
          label="OK"
          icon="pi pi-check"
          onClick={() => this.onHide(1)}
       
          className="btn add-event-btn text-16 font-semibold"
        />
      </div>
    );
  }

  render() {
    const today = new Date();
    return (
      <>
        <Modal.Header>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Dialog
          header="Confirmation"
          draggable={false}
          resizable={false}
          visible={this.state.displaySuccessMessage}
          footer={this.renderFooter()}
          onHide={() => this.onHide()}
        >
          <p>{this.state.SuccessMessage}</p>
        </Dialog>
   
          <div>
      
              <div className="container-fluid">
                <div className="row edit-profile-mobile-view">
                  <div className="col-lg-12">
                    <div className="userevent-flex container my-2">
                      <div className="row ">
                        <div className="col-md-6">
                          <label for="firstname" class="form-label text-14 font-semibold  mt-3 ">
                            First Name <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                      
                            type="text"
                            className="form-control"
                            name="FirstName"
                            id="FirstName"
                            value={this.state.FirstName}
                            onChange={this.onChange}
                            onKeyPress={this.handleKeypress}
                            maxLength="12"
                          />
                        </div>
                        <div className="col-md-6">
                          <label for="lastname" class="form-label text-14 font-semibold  mt-3 ">
                            Last Name <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="LastName"
                            id="LastName"
                            value={this.state.LastName}
                            onChange={this.onChange}
                            onKeyPress={this.handleKeypress}
                            maxLength="12"
                          />
                        </div>

                       <div className="col-md-6">

                            <label for="Email" class="form-label text-14 font-semibold mt-2">
                              Email <span style={{ color: "red" }}>*</span>
                            </label>

                            <input
                              className="form-control text-14 gray-input"
                              type="text"
                              disabled="true"
                              placeholder="Enter Email"
                              id="Email"
                              name="Email"
                              readOnly="true"
                              onChange={this.onChange}
                              onKeyPress={this.handleKeypress}
                              value={this.state.Email}
                            />

                          </div>

                        

                          <div className="col-md-6">

                            <label for="DOB" class="form-label text-14 font-semibold  mt-2">
                              DOB <span style={{ color: "red" }}>*</span>
                            </label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DatePicker
                                aria-describedby="DOB"
                                openTo="year"
                                format="dd/MM/yyyy"
                                views={["year", "month", "date"]}
                                value={this.state.DOB}
                                onChange={this.onChangeDate}
                                className="input-group-date"
                                maxDate={today}
                                placeholder="Enter DOB"
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                          <div className="col-md-6 ">

                            <label for="CountryId" class="form-label text-14 font-semibold mt-2">
                              Country
                              <span style={{ color: "red" }}> *</span>
                            </label>

                            <Autocomplete
                              key={this.state.CountryArray.Id}
                              style={{ width: "100%" }}
                              id="country-select-demo"
                              sx={{ width: 300 }}
                              options={this.state.CountryList}
                              getOptionLabel={(option) => {
                                if (option.hasOwnProperty("Name")) {
                                  return option.Name;
                                }
                                return option;
                              }}
                              onChange={this.onTagsChangeForCountry}
                              onKeyPress={this.handleKeypress}
                              value={this.state.CountryArray.Name}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{
                                    "& > img": { mr: 2, flexShrink: 0 },
                                  }}
                                  {...props}
                                >
                                  <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${option.ShortName.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${option.ShortName.toLowerCase()}.png 2x`}
                                    alt=""
                                  />
                                  {option.Name}
                                </Box>
                              )}
                              renderInput={(params) =>
                                this.state.CountryArray.Id ? (
                                  <TextField
                                    {...params}
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "new-password",
                                    }}
                                  />
                                ) : (
                                  <TextField
                                    {...params}
                                    label="Choose a country"
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                  />
                                )
                              }

                            />


                          </div>


                           <div className="col-md-6">
                            <label for="StateId" class="form-label text-14 font-semibold  mt-2">
                              State
                              <span style={{ color: "red" }}> *</span>
                            </label>

                            <Autocomplete
                             key={this.state.StateArray.Id}
                              style={{ width: "100%" }}
                              id="country-select-demo"
                              sx={{ width: 300 }}
                              options={this.state.StateList}
                              autoHighlight
                              getOptionLabel={(option) => {
                                if (option.hasOwnProperty("Name")) {
                                  return option.Name;
                                }
                                return option;
                              }}
                              onChange={this.onTagsChangeForState}
                              onKeyPress={this.handleKeypress}
                              value={this.state.StateArray.Name}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{
                                    "& > img": { mr: 2, flexShrink: 0 },
                                  }}
                                  {...props}
                                >
                                  {option.Name}
                                </Box>
                              )}
                              renderInput={(params) =>
                                this.state.StateArray.Id ? (
                                  <TextField
                                    {...params}
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "new-password",
                                    }}
                                  />
                                ) : (
                                  <TextField
                                    {...params}
                                    label="Choose a state"
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                  />
                                )
                              }
                            />

                          </div>
                        
                          <div className="col-lg-12">
                            <label for="MobileNo" class="form-label text-14 font-semibold  mt-2">
                              Mobile No <span style={{ color: "red" }}>*</span>
                            </label>

                            <input
                              className="form-control text-14"
                              type="text"
                              placeholder="Enter Phone Number"
                              id="MobileNo"
                              name="MobileNo"
                              onChange={(e) => {
                                const re = /^[0-9\b]+$/;

                                if (
                                  e.target.value === "" ||
                                  (re.test(e.target.value) && e.target.value.length <= 10)
                                ) {
                                  this.setState({ [e.target.name]: e.target.value });
                                }
                              }}
                              onKeyPress={this.handleKeypress}
                              value={this.state.MobileNo}
                            />
                          </div>


                          <div className="col-lg-12">
                          <label for="showidentity" class="form-label text-14 font-semibold mt-2">
                            Show Identity
                          </label>&nbsp;&nbsp;
                          <input
                            type="checkbox"
                            name="ShowIdentity"
                            checked={this.state.ShowIdentity}
                            onChange={this.onCheckboxChange}
                            onKeyPress={this.handleKeypress}
                          />
                       </div>
                        </div>
                      
                    </div>
                  </div>
                  <div className="col-lg-12 ">
                    <div className="choose-file my-4">
                      <div className="col-sm-4 text-center">
                        <div className="kv-avatar">
                          <div className="row">
                            <div>
                              <img
                                class="edit-user-profile"
                                src={
                                  this.state.UserPhotoImage
                                    ? UserIcon
                                    : this.state.NewImageValue
                                }
                              />
                            </div>
                          </div>
                    
                          <div className="row">
                            <div className="file-loading">
                              <input
                                accept="image/*"
                                type="file"
                                onChange={this.onImageChange}
                                onKeyPress={this.handleKeypress}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row ">
        <div className="d-flex  popup-error-msg">
              {this.state.ErrorMessage != "" ? (
                <>
                  <div
                    class="alert alert-danger d-flex align-items-center usermanagementalertmessage mb-0"
                    role="alert"
                  >
                    <div className="text-14">
                      <span>{this.state.ErrorMessage}</span>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          <div className=" mb-4  edit-profile d-flex align-items-center justify-content-end">
        
            <Button
              className="btn add-event-btn text-14 font-semibold mt-0"
              type="button"
              loading={this.state.Loading}
              onClick={this.onSaveClick}
              label={this.state.buttonLabel}
            ></Button>
            <Button
              label="Close"
              className="btn Create-user-cancle-btn text-14 font-semibold bg-lightblue text-button-grey ml-2 mr-2"
              onClick={this.onCloseClick}
            ></Button>
          </div>
        </div>
              </div>
    
          </div>
        

      
      </>
    );
  }
}

export default EditProfile;
