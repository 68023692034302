import React, { Component } from "react";
import axios from "axios";
import participicimage from "../../src/assets/images/logo/Participiclogo.png";
import Footer from "../Common/Footer";
import CommonValues from "../Common/Util";

class AddUserEventMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }

    componentDidMount() {
        document.body.classList.add("login-page");
        document.body.classList.remove("dashboard-page");
        const urlParams = new URLSearchParams(window.location.search);

        let user = ""
        user = urlParams.get("user");
        let pwd = ""
        pwd = urlParams.get("pwd");
        urlParams.delete("pwd");
        const url = urlParams
        const token = CommonValues.GetAuth0Token().replace(/^"|"$/g, "");
        if (token == null || token == "") {
            var data = JSON.stringify({
                Email: user,
                Password: pwd,
            });

            var config = {
                method: "post",
                url: process.env.REACT_APP_API + `User/Authenticate`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(config)
                .then((response) => {
                    if (response.data.Token != null || response.data.Token != "") {
                        localStorage.setItem("isAuthenticated", JSON.stringify(true));
                        localStorage.setItem(
                            "UserId",
                            JSON.stringify(response.data.UserId)
                        );
                        localStorage.setItem(
                            "UserName",
                            JSON.stringify(response.data.FirstName)
                        );
                        localStorage.setItem(
                            "MobileNo",
                            JSON.stringify(response.data.MobileNo)
                        );
                        localStorage.setItem("Email", JSON.stringify(response.data.Email));
                        localStorage.setItem(
                            "LastName",
                            JSON.stringify(response.data.LastName)
                        );
                        localStorage.setItem("Token", JSON.stringify(response.data.Token));
                        localStorage.setItem(
                            "RoleId",
                            JSON.stringify(response.data.RoleId)
                        );
                        localStorage.setItem(
                            "RoleName",
                            JSON.stringify(response.data.RoleName)
                        );

                        localStorage.setItem(
                            "ProfileImageURL",
                            JSON.stringify(response.data.ImageURL)
                        );

                        window.location.replace('/AddUserEvent/C/0')

                    } else {
                        window.location.replace('/login')
                    }
                })
                .catch((error) => {
                    window.location.replace('/login')
                });
        } else {
            window.location.replace('/AddUserEvent/C/0')
        }

    }

    render() {
        return (
            <div>
                <main class="main-content">
                    <div class="login-form-wrapper">
                        <div class="login-form-radius">
                            <div class="login-form-content">
                                <a href="/" title="Participic">
                                    <img
                                        src={participicimage}
                                        width="320"
                                        height="51"
                                        alt="Participic"
                                        class="logo-fit mt-0"
                                    />
                                </a>

                                <h2 class="text-navyblue text-16 font-semibold">
                                    Please Wait...
                                </h2>

                            </div>
                        </div>
                        <Footer></Footer>
                    </div>
                </main>
            </div>
        );
    }
}

export default AddUserEventMobile;
