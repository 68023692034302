import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import axios from "axios";
import moment from "moment";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { Component } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import validator from "validator";
class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Firstname: "",
      Lastname: "",
      Email: "",
      MobileNo: "",
      RoleId: 0,
      Password: "",
      Loading: false,
      ErrorMessage: "",
      SuccessMessage: "",
      ConfirmPassword: "",
      DOB: null,
      CountryList: [],
      CountryId: 0,
      StateList: [],
      stateedit: true,
      StateId: 0,
      ModelTitle: "",
      ButtonLabel: "",
      RoleList: [],
      displaySuccessMessage: false,
      CountryArray: [],
      StateArray: [],
    //  today1: new Date(),
    };
    this.onTagsChangeForCountry = this.onTagsChangeForCountry.bind(this);
    this.onTagsChangeForState = this.onTagsChangeForState.bind(this);
    if (this.props.UserData.CountryId != null || this.props.UserData.CountryId != undefined) {
      this.GetCountries();
      this.GetStatesByCountryId(this.props.UserData.CountryId);
    }

  }

  componentDidMount() {
    if (this.props.Mode == "C") {
      this.GetCountries();
      this.setState({
        Firstname: "",
        Lastname: "",
        Email: "",
        MobileNo: "",
        DOB: null,
        CountryId: parseInt(0),
        StateId: parseInt(0),
        RoleId: this.state.RoleId,
        Password: "",
        ConfirmPassword: "",
        ModelTitle: "Add User",
        ButtonLabel: "Save",
      });
    } else {
      if (
        this.props.UserData.Countrys === undefined ||
        this.props.UserData.Countrys === null
      ) {
        this.setState({
          Firstname: this.props.UserData.FirstName,
          Lastname: this.props.UserData.LastName,
          Email: this.props.UserData.Email,
          MobileNo: this.props.UserData.MobileNo,
          DOB: this.props.UserData.DOB,
          CountryId: parseInt(this.props.UserData.CountryId),
          StateId: parseInt(this.props.UserData.StateId),
          RoleId: parseInt(this.props.UserData.RoleId),
          Password: "",
          ConfirmPassword: "",
          ModelTitle: "Edit User",
          ButtonLabel: "Save",
          CountryArray: [],
          StateArray: [],
        });
      } else {
        if (this.props.UserData.Countrys == null) {
          this.state.CountryArray = [];
          this.setState({
            CountryArray: this.state.CountryArray,
          });
        } else {
          this.setState({
            CountryArray: this.props.UserData.Countrys,
          });
        }
        if (this.props.UserData.States == null) {
          this.state.States = [];
          this.setState({
            States: this.state.States,
          });
        } else {
          this.setState({
            States: this.props.UserData.States,
          });
        }

        this.setState(
          {
            Firstname: this.props.UserData.FirstName,
            Lastname: this.props.UserData.LastName,
            Email: this.props.UserData.Email,
            MobileNo: this.props.UserData.MobileNo,
            DOB: this.props.UserData.DOB,
            CountryId: parseInt(this.props.UserData.CountryId),
            StateId: parseInt(this.props.UserData.StateId),
            RoleId: parseInt(this.props.UserData.RoleId),
            Password: "",
            ConfirmPassword: "",
            ModelTitle: "Edit User",
            ButtonLabel: "Save",
            CountryArray: this.props.UserData.Countrys,
            StateArray: this.props.UserData.States,
          },

          () => {
            this.onTagsChangeForCountry(1, this.state.CountryArray);
            this.onTagsChangeForState(1, this.state.StateArray);
          });
      }
      this.GetCountries();
      this.GetStatesByCountryId(this.props.UserData.CountryId);
    }
    this.getAllRoles();
  }
  GetCountries = () => {
    var config = {
      method: "get",
      url: process.env.REACT_APP_EVENT_API + `Event/GetCountries`,
      headers: {},
    };

    axios(config)
      .then((response) => {
        response.data.forEach((element) => {
          this.state.CountryList.push(element);
        });
      })
      .catch((error) => {
        if (error.response.status == 401) {
          window.location.pathname = "/logout";
        } else {
          this.setState({
            ErrorMessage: "Error while get all countries",
            SuccessMessage: "",
          });
        }
      });
  };
  GetStatesByCountryId = (id) => {
    if (id == undefined || id == 0) {
      id = 0;
    }
    var config = {
      method: "get",
      url:
        process.env.REACT_APP_EVENT_API + `Event/GetStatesByCountryId?Id=${id}`,
      headers: {},
    };

    axios(config)
      .then((response) => {
        const obj = {
          Id: 0,

          Name: "--Select the State--",

          CountryId: 0,
        };

        const data = response.data;

        data.push(obj);

        this.setState({
          StateList: data,

          CountryId: id,
        });
      })

      .catch((error) => {
        this.setState({
          ErrorMessage: "Error while gettting event get states by countryid",
        });
        if (error.response.status == 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };
  getAllRoles = () => {
    var config = {
      method: "get",
      url: process.env.REACT_APP_API + `User/GetAllRoles`,
      headers: {},
    };

    axios(config)
      .then((response) => {
        const role = [];
        role.push({
          RoleId: 0,
          RoleName: "---Select Role---",
        });
        const roleList = [];
        response.data.forEach((element) => {
          roleList.push(element);
        });
        const finalRoleList = [...role, ...roleList];
        this.setState({
          RoleList: finalRoleList,
        });
      })
      .catch((error) => {
        if (error.response.status == 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };
  onTagsChangeForCountry = (event, values) => {
    if (values != null) {
      this.setState(
        {
          CountryArray: values,
          StateArray: [],
        },
        () => {
          this.setState({
            StateList: [],
          });
          this.GetStatesByCountryId(this.state.CountryArray.Id);
          if (this.state.CountryArray.Id == 0) {
            this.setState({
              countryCss: 0,
            });
          } else {
            this.setState({
              CountryId: this.state.CountryArray.Id,
              stateedit: false,
              countryCss: 1,
              StateId: 0,
            });
          }
        }
      );
    } else {
      this.setState({
        CountryArray: [],
        StateList: [],
        StateArray: [],
        stateedit: true,

        CountryId: 0,
        StateId: 0,
      });
    }
  };
  onTagsChangeForState = (event, values) => {
    if (values != null) {
      this.setState(
        {
          StateArray: values,
        },
        () => {
          if (this.state.StateArray.Id == 0) {
            this.setState({
              stateCss: 0,
            });
          } else {
            this.setState({
              stateCss: 1,
              StateId: this.state.StateArray.Id,
            });
          }
        }
      );
    } else {
      this.setState({
        StateArray: [],
        StateId: 0,
      });
    }
  };
  onChangeDate = (date) => {
    this.setState({
      DOB: date,
    });
  };
  handleChange = (e) => {
    this.setState({
      RoleId: e.target.value,
    });
  };
  handleCountryChange = (e) => {
    this.setState({
      StateList: [
        {
          Id: 0,
          ShortName: "NN",
          Name: "--Select the State--",
        },
      ],
    });
    this.GetStatesByCountryId(e.target.value);
    if (e.target.value == 0) {
    } else {
      this.setState({
        StateId: 0,
      });
      this.stateInput.focus();
    }
  };

  handleStateChange = (e) => {
    this.setState({
      StateId: e.target.value,
    });
  };
  onCloseClick = () => {
    this.props.onCloseClick();
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onMobileNumberChange = (event) => {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode < 31 && (charCode > 48 || charCode < 57)) {
      return false;
    }

    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleKeypress = (e) => {
    if (e.key == "Enter") {
      this.onSaveClick();
    }
  };

  validate = () => {
    let valid = true;

    if (
      this.state.Firstname == "" &&
      this.state.Lastname == "" &&
      this.state.Email == "" &&
      this.state.MobileNo == "" &&
      this.state.DOB == "" && 
      this.state.DOB == null &&
      this.state.CountryId == "" &&
      this.state.StateId == "" &&
      this.state.RoleId == "" &&
      this.state.Password == "" &&
      this.state.ConfirmPassword == "" &&
      this.state.Firstname == null
    ) {
      this.setState({
        ErrorMessage: "Please enter all details",
        SuccessMessage: "",
      });
      return false;
    }
    if (this.state.Firstname == null) {
      this.setState({
        ErrorMessage: "Please enter first name",
        SuccessMessage: "",
      });
      return false;
    }
   if (this.state.Firstname.trim() == "") 
{
      this.setState({
        ErrorMessage: "Please enter first name",
        SuccessMessage: "",
      });
      return false;
    }
    if (this.state.Lastname.trim() == "") {
      this.setState({
        ErrorMessage: "Please enter last name",
        SuccessMessage: "",
      });
      return false;
    }
    if (this.state.Email == "") {
      this.setState({
        ErrorMessage: "Please enter email id",
        SuccessMessage: "",
      });
      return false;
    }
     if (this.state.MobileNo ==null) {
       this.setState({
         ErrorMessage: "Please enter mobile No",
         SuccessMessage: "",
       });
       return false;
     }
    if (!validator.isEmail(this.state.Email)) {
      this.setState({
        ErrorMessage: "Please enter valid email id",
        SuccessMessage: "",
      });
      return false;
    }
    if (this.state.MobileNo.length != 10) {
      this.setState({
        ErrorMessage: "Please enter the valid mobile number",
        SuccessMessage: "",
      });
      return false;
    }

    if (this.state.DOB == "" || this.state.DOB == null) {
      this.setState({
        ErrorMessage: "Please select DOB",
        SuccessMessage: "",
      });
      return false;
    }
    if (moment(this.state.today1).diff(moment(this.state.DOB), "years") < 14) {

      this.setState({
        ErrorMessage: "Age under 14 is not allowed.",
        SuccessMessage: "",
      });
      return false;
    }
    if (this.state.CountryId == 0) {
      this.setState({
        ErrorMessage: "Please select a Country",
        SuccessMessage: "",
      });
      return false;
    }
    if (this.state.StateId == 0) {
      this.setState({
        ErrorMessage: "Please select a state",
        SuccessMessage: "",
      });
      return false;
    }

    if (this.state.RoleId == "" || this.state.RoleId == 0) {
      this.setState({
        ErrorMessage: "Please select role",
        SuccessMessage: "",
      });
      return false;
    }

    if (this.props.Mode == "C") {
      if (this.state.Password == "") {
        this.setState({
          ErrorMessage: "Please enter password",
          SuccessMessage: "",
        });
        return false;
      }

      if (
        validator.isStrongPassword(this.state.Password, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        this.setState({
          ErrorMessage:
            "Note: Password must contain minimum of 8 characters and it should have atleast one uppercase, lowercase, number and special character.",
          SuccessMessage: "",
        });
      } else {
        this.setState({
          ErrorMessage:
            "Note: Password must contain minimum of 8 characters and it should have atleast one uppercase, lowercase, number and special character.",
          SuccessMessage: "",
        });
        return false;
      }

      if (
        this.state.ConfirmPassword == "" ||
        this.state.ConfirmPassword == null
      ) {
        this.setState({
          ErrorMessage: "Please enter confirm password",
          SuccessMessage: "",
        });
        return false;
      }

      if (this.state.Password != this.state.ConfirmPassword) {
        this.setState({
          ErrorMessage: "Password do not match",
          SuccessMessage: "",
        });
        return false;
      }
    }

    this.setState({
      ErrorMessage: "",
    });

    return valid;
  };

  onSaveClick = () => {
    if (this.validate()) {
      if (this.props.Mode == "C") {
        this.setState({ SuccessMessage: "" });
        const newDate = moment(this.state.DOB).format("YYYY-MM-DD");
        var axios = require("axios");
        var data = JSON.stringify({
          UserId: 0,
          FirstName: this.state.Firstname,
          LastName: this.state.Lastname,
          Email: this.state.Email,
          MobileNo: this.state.MobileNo,
          DOB: newDate,
          CountryId: parseInt(this.state.CountryId),
          StateId: parseInt(this.state.StateId),
          RoleId: parseInt(this.state.RoleId),
          Password: this.state.Password,
        });

        var config = {
          method: "post",
          url: process.env.REACT_APP_API + `User/CreateUser`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        this.setState({
          Loading: true,
        });
        axios(config)
          .then((response) => {
            this.onClick();
            this.setState({
              Loading: false,
              SuccessMessage: "User created successfully...!!!",
            });
            this.props.onRefreshClick();
          })
          .catch((error) => {
            if (error.response.status == 409) {
              this.onClick();
              this.setState({
                Loading: false,
                ErrorMessage: "",
                SuccessMessage: "Email address is already exists",
              });
            } else {
              console.log(error);
              this.setState({
                ErrorMessage: "Error while creating users",
                SuccessMessage: "",
              });
            }
            if (error.response.status == 401) {
              window.location.pathname = "/logout";
            } else {
              console.log(error);
            }
          });
      } else {
        const newDate = moment(this.state.DOB).format("YYYY-MM-DD");
        var axios = require("axios");
        var data = JSON.stringify({
          UserId: this.props.EditClickUserId,
          FirstName: this.state.Firstname,
          LastName: this.state.Lastname,
          Email: this.state.Email,
          MobileNo: this.state.MobileNo,
          DOB: newDate,
          CountryId: parseInt(this.state.CountryId),
          StateId: parseInt(this.state.StateId),
          RoleId: parseInt(this.state.RoleId),
          Password: this.state.Password,
        });

        var config = {
          method: "put",
          url: process.env.REACT_APP_API + `User/UpdateUser`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        this.setState({
          Loading: true,
        });
        axios(config)
          .then((response) => {
            this.onClick();
            this.setState({
              Loading: false,
              SuccessMessage: "User updated successfully...!!!",
            });
            this.props.onRefreshClick();
          })
          .catch((error) => {
            if (error.response.status == 409) {
              this.onClick();
              this.setState({
                Loading: false,
                SuccessMessage: "Email address is already exists",
                ErrorMessage: "",
              });
            } else {
              console.log(error);
              this.setState({
                ErrorMessage: "Error while updating users",
                SuccessMessage: "",
              });
            }
            if (error.response.status == 401) {
              window.location.pathname = "/logout";
            } else {
              console.log(error);
            }
          });
      }
    }
  };

  onClick() {
    this.setState({
      displaySuccessMessage: true,
    });
  }

  onHide() {
    this.onCloseClick();
  }

  renderFooter() {
    return (
      <div>
        <Button
          label="OK"
          icon="pi pi-check"
          onClick={() => this.onHide()}
       
          className="btn myProfile-btn text-16 font-semibold"
        />
      </div>
    );
  }
  renderInput = (props) => (
    <div class="text-left">
      <input
        type="text"
        className="form-control"
        placeholder="Enter DOB"
        onClick={props.onClick}
        value={props.value}
        onChange={props.onChange}
      />
    </div>
  );

  render() {
    const today = new Date();
    return (
      <>
        <Modal.Header>
          <Modal.Title>{this.state.ModelTitle}</Modal.Title>
        </Modal.Header>
        <Dialog
          header="Confirmation"
          visible={this.state.displaySuccessMessage}
          className="small-modal-conf"
          draggable={false}
          resizable={false}
          footer={this.renderFooter()}
          onHide={() => this.onHide()}
        >
          <p className="pt-4">{this.state.SuccessMessage}</p>
        </Dialog>
        <Modal.Body>
          <Form className="add-user-form">
            <div class="row  align-items-center">
              <div class="col-md-4">
                <label for="Firstname" class="col-form-label">
                  First Name <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div class="col-md-8">
                <input
                  type="text"
                  placeholder="Enter First Name"
                  id="Firstname"
                  className="form-control text-14"
                  name="Firstname"
                  maxLength={12}
                  onChange={this.onChange}
                  onKeyPress={this.handleKeypress}
                  value={this.state.Firstname}
                />
              </div>
            </div>

            <div class="row align-items-center">
              <div class="col-md-4">
                <label for="Lastname" class="col-form-label">
                  Last Name <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div class="col-md-8">
                <input
                  className="form-control text-14"
                  type="text"
                  placeholder="Enter Last Name"
                  id="Lastname"
                  name="Lastname"
                  maxLength={12}
                  onChange={this.onChange}
                  onKeyPress={this.handleKeypress}
                  value={this.state.Lastname}
                />
              </div>
            </div>

            <div class="row align-items-center">
              <div class="col-md-4">
                <label for="Email" class="col-form-label">
                  Email <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div class="col-md-8">
                <input
                  className="form-control text-14"
                  type="text"
                  placeholder="Enter Email"
                  id="Email"
                  name="Email"
                  onChange={this.onChange}
                  onKeyPress={this.handleKeypress}
                  value={this.state.Email}
                />
              </div>
            </div>

            <div class="row align-items-center">
              <div class="col-md-4">
                <label for="MobileNo" class="col-form-label">
                  Mobile No <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div class="col-md-8">
                <input
                  className="form-control text-14"
                  type="text"
                  placeholder="Enter Phone Number"
                  id="MobileNo"
                  name="MobileNo"
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;

                    if (
                      e.target.value === "" ||
                      (re.test(e.target.value) && e.target.value.length <= 10)
                    ) {
                      this.setState({ [e.target.name]: e.target.value });
                    }
                  }}
                  onKeyPress={this.handleKeypress}
                  value={this.state.MobileNo}
                />
              </div>
            </div>

            <div class="row  align-items-center">
              <div class="col-md-4">
                <label for="DOB" class="col-form-label">
                  DOB <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div class="col-md-8">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    aria-describedby="DOB"
                    openTo="year"
                    format="dd/MM/yyyy"
                    views={["year", "month", "date"]}
                    value={this.state.DOB}
                    onChange={this.onChangeDate}
                    className="input-group-date"
                    maxDate={today}
                    placeholder="Enter DOB"
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>

            <div class="row align-items-center userevent-flex">
              <div class="col-md-4">
                <label for="CountryId" class="col-form-label">
                  Country
                  <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div class="col-md-8">
                <Autocomplete
                  key={new Date()}
                  style={{ width: "100%" }}
                  id="country-select-demo"
                  sx={{ width: 300 }}
                  options={this.state.CountryList}
                  getOptionLabel={(option) => {
                    if (option.hasOwnProperty("Name")) {
                      return option.Name;
                    }
                    return option;
                  }}
                  onChange={this.onTagsChangeForCountry}
                  onKeyPress={this.handleKeypress}
                  value={this.state.CountryArray.Name}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{
                        "& > img": { mr: 2, flexShrink: 0 },
                      }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.ShortName.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.ShortName.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.Name}
                    </Box>
                  )}
                  renderInput={(params) =>
                    this.state.CountryArray.Id ? (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                      />
                    ) : (
                      <TextField
                        {...params}
                        label="Choose a country"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )
                  }
                />
              </div>
            </div>

            <div class="row align-items-center userevent-flex">
              <div class="col-md-4">
                <label for="StateId" class="col-form-label">
                  State
                  <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div class="col-md-8">
                <Autocomplete
                  key={new Date()}
                  style={{ width: "100%" }}
                  id="country-select-demo"
                  sx={{ width: 300 }}
                  options={this.state.StateList}
                  autoHighlight
                  getOptionLabel={(option) => {
                    if (option.hasOwnProperty("Name")) {
                      return option.Name;
                    }
                    return option;
                  }}
                  onChange={this.onTagsChangeForState}
                  onKeyPress={this.handleKeypress}
                  value={this.state.StateArray.Name}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{
                        "& > img": { mr: 2, flexShrink: 0 },
                      }}
                      {...props}
                    >
                      {option.Name}
                    </Box>
                  )}
                  renderInput={(params) =>
                    this.state.StateArray.Id ? (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                      />
                    ) : (
                      <TextField
                        {...params}
                        label="Choose a state"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )
                  }
                />
              </div>
            </div>

            <div class="row align-items-center">
              <div class="col-md-4">
                <label for="RoleId" class="col-form-label">
                  Role <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div class="col-md-8">
                <select
                  className="form-control text-14"
                  value={this.state.RoleId}
                  onChange={this.handleChange}
                  onKeyPress={this.handleKeypress}
                >
                  {this.state.RoleList.map((role) => {
                    return <option value={role.RoleId}>{role.RoleName}</option>;
                  })}
                </select>
              </div>
            </div>
    
            {this.props.Mode == "C" ? (
              <>
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <label for="Password" class="col-form-label">
                      Password <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div class="col-md-8">
                    <input
                      className="form-control text-14"
                      type="password"
                      placeholder="Enter Password"
                      id="Password"
                      name="Password"
                      onChange={(e) => {
                        this.setState({
                          [e.target.name]: e.target.value.trim(),
                        });
                      }}
                      onKeyPress={this.handleKeypress}
                      value={this.state.Password}
                    />
                  </div>
                </div>
       
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <label for="ConfirmPassword" class="col-form-label">
                      Confirm Password <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div class="col-md-8">
                    <input
                      className="form-control text-14"
                      type="password"
                      placeholder="Confirm Password"
                      id="ConfirmPassword"
                      name="ConfirmPassword"
                      onChange={(e) => {
                        this.setState({
                          [e.target.name]: e.target.value.trim(),
                        });
                      }}
                      onKeyPress={this.handleKeypress}
                      value={this.state.ConfirmPassword}
                    />
                  </div>
                </div>
              </>
            ) : null}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          &nbsp;
          <div class="d-flex ">
            <div class="flex-grow-1 bd-highlight mr-5">
              {this.state.ErrorMessage != "" ? (
                <>
                  <div
                    class="alert alert-danger d-flex align-items-center usermanagementalertmessage"
                    role="alert"
                  >
                    <div className="text-14">
                      <span className="ml-1">{this.state.ErrorMessage}</span>
                    </div>
                  </div>
                </>
              ) : null}
              {this.state.Loading === true ? (
                <div style={{ verticalAlign: "center" }}>
                  <Spinner animation="border" variant="warning" />
                  <b>{this.state.LoadingMessage}</b>
                </div>
              ) : null}
            </div>
            <div class="bd-highlight mr-1 ml-0">
              {" "}
              <button
                className="btn btn-primary add-event-btn text-13 font-semibold"
                type="submit"
                onClick={this.onSaveClick}
              >
                {this.state.ButtonLabel}
              </button>
            </div>
            <div class="bd-highlight">
              <Button
                label="Close"
                className="btn Create-user-cancle-btn text-13 font-semibold bg-lightblue text-button-grey"
                onClick={this.onCloseClick}
              ></Button>
            </div>
          </div>
        </Modal.Footer>
      </>
    );
  }
}

export default AddUser;
