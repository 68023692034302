import axios from "axios";
import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Spinner } from "react-bootstrap";
import PhotoNotfound from "../../src/assets/images/Bg/no-image.png";
class PhotoSlider extends Component {
  constructor(props) {
    super(props);
    this.sliderInterval = null;
    this.state = {
      EventPhotoDetails: [],
      showOnlyImage: this.props.showOnlyImage,
      PhotoObject: this.props.PhotoObject,
      Loading: true,
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;

    const eventId = params.eventid;

    this.getAllApprovedPhotosByEventId(eventId);

    this.sliderInterval = setInterval(
      this.getAllApprovedPhotosByEventId,
      process.env.REACT_APP_PHOTO_SLIDER_INTERVAL,
      eventId
    );
  }

  tooglePhotoSliderPopup = (val) => {
    this.props.tooglePhotoSliderPopup(val);
  };

  componentWillUnmount() {
    if (this.sliderInterval) clearInterval(this.sliderInterval);
  }

  getAllApprovedPhotosByEventId = (eventId) => {
    var config = {
      method: "get",
      url:
        process.env.REACT_APP_EVENT_API +
        `Event/GetEventPhotoByEventIdAndApprovedRejectedDateTime?eventId=${eventId}&&ApprovedRejectedDateTime=0`,
    };

    axios(config)
      .then((response) => {
        this.setState({
          showOnlyImage: true,
          EventPhotoDetails: response.data,
          Loading: false,
        });
      })
      .catch((error) => {
        if (error.response.status == 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };

  getAllApprovedPhotos = (eventId) => {
    var maxDate =
      Math.max(
        ...this.state.EventPhotoDetails.map((element) => {
          return new Number(element.ApprovedRejectedDateTime);
        })
      ) || 0;

    if (maxDate === "-Infinity") {
      maxDate = 0;
    }

    var config = {
      method: "get",
      url:
        process.env.REACT_APP_EVENT_API +
        `Event/GetEventPhotoByEventIdAndApprovedRejectedDateTime?eventId=${eventId}&&ApprovedRejectedDateTime=${maxDate}`,
    };

    axios(config)
      .then((response) => {
        var approvedData =
          response.data.filter((data) => data.Status === "A") || [];
        var rejectedData =
          response.data.filter((data) => data.Status === "R") || [];

        var finalData = [...this.state.EventPhotoDetails];

        if (approvedData.length > 0) {
          var finalData = [...this.state.EventPhotoDetails, ...approvedData];
        }
        if (rejectedData.length > 0) {
          finalData = finalData.filter(
            (ar) =>
              !rejectedData.find((rm) => rm.EventPhotoId === ar.EventPhotoId)
          );
        }

        this.setState({ EventPhotoDetails: finalData });
      })
      .catch((error) => {
        if (error.response.status == 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };

  render() {
    const settings = {
      autoplay: true,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    if (this.state.EventPhotoDetails.length === 0) {
      return (
        <>
          <div className="page-not-found">
            {this.state.Loading == true ? (
              <>
                <div>
                  <Spinner animation="border" variant="warning" />{" "}
                </div>
              </>
            ) : (
              <>
                <img src={PhotoNotfound} />
              </>
            )}
          </div>
        </>
      );
    } else {
      return (
        <div className="photo-slide">
          <Slider {...settings}>
            {this.state.EventPhotoDetails.map((photo, index) => {
              return (
                <>
                  <div className="each-slide each-pop-slider" key={index}>
                    {" "}
                    <img src={photo.highresolution} />
                  </div>
                </>
              );
            })}
          </Slider>
        </div>
      );
    }
  }
}
export default PhotoSlider;
