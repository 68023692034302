import { Dialog } from "@material-ui/core";
import { Button } from "primereact/button";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Redirect } from "react-router-dom";
import "react-tagsinput/react-tagsinput.css";
import ReactToPrint from "react-to-print";
import CommonValues from "../Common/Util";

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      html1: this.props.html1,
      PromoCode: this.props.PromoCode,
      EventName: this.props.EventName,
      bodyData: this.props.bodyData,
      NewFinalData: "",
    };
  }
  componentDidMount = () => {
    this.setState({
      html1: `${process.env.REACT_APP_PARTICIPIC_IMAGE}`,
      PromoCode: `https://api.qrserver.com/v1/create-qr-code/?data=${this.props.PromoCode}&size=200x200&bgcolor=#ffffff`,
    });
    let NewFinalData1 = this.props.bodyData;
    this.setState({
      NewFinalData: NewFinalData1,
    });
  };
  removeHTML(str) {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || "";
  }
  render() {
    return (
      <>
        <div className="modal-body">
          <div className="create-event-wrapper send-email-popup mt-1">
            <div className="container-fluid"></div>
            <div className="col-lg-6 text-center qrcodemargin">
              <img src={this.state.html1} alt="" />
            </div>
            <div className="col-lg-6 text-center qrcodemargin">
              <img
                src={this.state.PromoCode}
                width="300px"
                height="300px"
                alt=""
              />
            </div>
          </div>
          <div style={{ marginTop: "100px" }}>
            <h1 style={{ textAlign: "center", fontSize: "50px" }}>
              {this.state.EventName}
            </h1>
          </div>
          <div
            style={{
              textAlign: "center",
              marginTop: "150px",
              lineHeight: "normal",
            }}
          >
            <div
              dangerouslySetInnerHTML={{ __html: this.state.NewFinalData }}
            />
          </div>
        </div>
      </>
    );
  }
}
class ScannerCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ModelTitle: "",
      ToggleSideBar: true,
      Loading: false,
      ErrorMessage: "",
      SuccessMessage: "",
      displaySuccessMessage: false,
      PromoCode: "",
      EventName: "",
    };
  }
  ToggleSideBar = () => {
    this.setState({
      ToggleSideBar: !this.state.ToggleSideBar,
    });
  };

  handleChange = (e) => {
    this.setState({ tags: e });
  };

  onClick() {
    this.setState({
      displaySuccessMessage: true,
    });
  }

  onClick() {
    this.setState({
      displaySuccessMessage: true,
    });
  }
  onHide() {
    this.onCloseClick();
  }

  renderFooter() {
    return (
      <div>
        <Button
          label="OK"
          icon="pi pi-check"
          onClick={() => this.onHide()}
          autoFocus
          className="btn myProfile-btn text-16 font-semibold"
        />
      </div>
    );
  }

  onCloseClick = () => {
    this.props.onCloseClick();
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return (
      <div>
        <Dialog
          header="Confirmation"
          draggable={false}
          resizable={false}
          visible={this.state.displaySuccessMessage}
          style={{ width: "20vw" }}
          footer={this.renderFooter()}
          onHide={() => this.onHide()}
        >
          <p>{this.state.SuccessMessage}</p>
        </Dialog>
        <Modal.Header closeButton>
          <Modal.Title>Print Confirmation</Modal.Title>
        </Modal.Header>
        <div className="modal-body">
          <div className="create-event-wrapper send-email-popup mt-1">
            <div className="container-fluid"></div>
            <div className="col-lg-12 text-center">
              <h1>
                Do you want to print the QR code of {this.props.EventName}?
              </h1>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div>
            <span>
              <ReactToPrint
                trigger={() => (
                  <button className="btn btn-primary add-event-btn font-semibold ">
                    Yes
                  </button>
                )}
                content={() => this.componentRef}
                onAfterPrint={this.onCloseClick}
              />
            </span>
          </div>
          <div style={{ display: "none" }}>
            <ComponentToPrint
              ref={(el) => (this.componentRef = el)}
              PromoCode={this.props.PromoCode}
              EventName={this.props.EventName}
              bodyData={this.props.bodyData}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default ScannerCode;
