import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import CommonValues from "../Common/Util";
import DownArrowIcon from "./../assets/images/downarrow.jpg";
import UserIcon from "./../assets/images/user.png";
import Auth from "../Auth/Auth";
import boxArrowDown from "./../assets/images/box-arrow-down.png";
class Header extends Component {
  constructor(props) {
    super(props);
    this.auth = Auth;
    this.state = {
      LoggedInUserName: "",
      ProfileImageURL: "",
      HeaderValue: "",
      dropdownValue: false,
      UserPhotoImage: false,
    };
  }

  componentDidMount = () => {
    if (this.props.EventDashboardValue == 1) {
      this.setState({
        HeaderValue: "Create Event & Manage",
      });
    } else if (this.props.DynamicUserEvent == "") {
      this.setState({
        HeaderValue: "",
      });
    } else if (this.props.EventDashboardValue == 2) {
      this.setState({
        HeaderValue: "User Management",
      });
    } else if (this.props.EventDashboardValue == 3) {
      this.setState({
        HeaderValue: "Change Password",
      });
    } else if (this.props.EventDashboardValue == 4) {
      this.setState({
        HeaderValue: "My Profile",
      });
    } else if (this.props.EventDashboardValue == 5) {
      this.setState({
        HeaderValue: "Event Invitation ",
      });
    } else {
      this.setState({
        HeaderValue: "Dashboard",
      });
    }
    let loggedinusername = CommonValues.GetUserName();
    if (loggedinusername == null) {
      this.setState({ LoggedInUserName: "" });
    } else {
      this.setState({ LoggedInUserName: loggedinusername });
    }

    this.ContainsAny(this.state.ProfileImageURL, [
      ".jpg",
      ".png",
      ".svg",
      ".jpeg",
      ".JPG",
      ".PNG",
      ".SVG",
      ".JPEG",
      ".avif"
    ]);
  };

  onDropDownMenuClick = () => {
    this.setState({
      dropdownValue: !this.state.dropdownValue,
    });
  };
  onToggleClick = () => {
    this.props.onSideBarToggle();
    this.props.onToggleButtonClick();
  };

  onWelcomeClick = () => {
    window.location.pathname = "/MyProfile";
  };
  ContainsAny(str, items) {
     let image = CommonValues.GetProfileImageURL();
   
     if (image.indexOf('null') >= 0 || image.indexOf('null') >= 0 || image==null || image=="" || image ==undefined) 
    {
      this.setState({ ProfileImageURL: "https://d1yfz6g5rx4pjm.cloudfront.net/EventZip/assets/images/noImageavtar.jpg"});    
    }
    else{
    if (image != null || image != undefined) {
      if (image.indexOf('\'') >= 0 || image.indexOf('"') >= 0) 
      { 
        let newImageURL = JSON.parse(image);
        this.setState({ ProfileImageURL: newImageURL });        
      }else
      {
        this.setState({ ProfileImageURL: image }); 
      }
    } else {
      this.setState({ ProfileImageURL: "" });
    }
  }
    return false;
  }
  render() {
    const RoleName = CommonValues.GetRoleName();
    return (
      <>
        <div class="container-fluid dashboard-header-nav">
          <div class="page-title">
            <div class="row align-items-center ">
              <div class="col-9 col-lg-4">
                <h1
                  class="text-24 font-light text-darkblue"
                  id="dashboard"
                  className="dashboard"
                >
                  {this.props.DynamicUserEvent == null ||
                  this.props.DynamicUserEvent == ""
                    ? this.state.HeaderValue
                    : this.props.DynamicUserEvent}
                </h1>
              </div>
              <div className="col-5 col-lg-8 d-none d-md-none d-lg-block">
                <ul class="header-top-right-menus">
                  <li class="profile-wrapper">
                    <a
                      class="profile-box dropdown-toggle"
                      id="dropdownprofileButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        class="user-header-img"
                        onClick={this.onWelcomeClick}
                        src={
                          this.state.UserPhotoImage
                            ? UserIcon
                            : this.state.ProfileImageURL
                        }
                        title="My Profile"
                        alt="User Photo"
                      />
                      <span class="profile-name-rights">
                        <span
                          onClick={this.onWelcomeClick}
                          class="font-weight-bolder text-16"
                          title="My Profile"
                        >
                          Welcome {this.state.LoggedInUserName}
                        </span>
                        <span class="text-12 font-weight-bold text-muted d-block">
                          {RoleName}
                        </span>
                      </span>
                      <img
                        src={DownArrowIcon}
                        width="20"
                        height="20"
                        alt="down arrow"
                        onClick={() => this.onDropDownMenuClick(true)}
                      />
                    </a>
                    <div
                      class={
                        this.state.dropdownValue
                          ? "dropdown-menu-new show"
                          : "dropdown-menu"
                      }
                      aria-labelledby="dropdownprofileButton"
                    >
                      <Link to="/logout" className="dropdown-item">
                        <span> Logout</span>
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="col-3 col-lg-8 text-end d-md-block d-lg-none d-block">
                <div class="dropdown">
                  <a
                    class="dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      class="user-header-img"
                      src={
                        this.state.UserPhotoImage
                          ? UserIcon
                          : this.state.ProfileImageURL
                      }
                      alt="User Photo"
                    />
                    <img
                      src={DownArrowIcon}
                      width="20"
                      height="20"
                      alt="down arrow"
                      onClick={() => this.onDropDownMenuClick(true)}
                    />
                  </a>
                  <ul
                    class="dropdown-menu mt-1 drop-menu-width"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <Link to="/logout" class="dropdown-item">
                        Logout
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Header);
