import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import axios from "axios";
import moment from "moment";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { Component } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css//
import "react-datepicker/dist/react-datepicker-cssmodules.css";
class UserInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FirstName: "",
      LastName: "",
      Email: "",
      MobileNo: "",
      RoleId: 0,
      Password: "",
      Loading: false,
      ErrorMessage: "",
      SuccessMessage: "",
      ConfirmPassword: "",
      DOB: null,
      CountryList: [],
      CountryId: 0,
      StateList: [],
      stateedit: true,
      StateId: 0,
      ModelTitle: "",
      ButtonLabel: "",
      RoleList: [],
      displaySuccessMessage: false,
      CountryArray: [],
      StateArray: [],
      today1: new Date(),
    };
    this.onTagsChangeForCountry = this.onTagsChangeForCountry.bind(this);
    this.onTagsChangeForState = this.onTagsChangeForState.bind(this);
  }

  componentDidMount() {
    this.GetCountries();
    this.setState({
      Firstname: "",
      Lastname: "",
      Email: "",
      MobileNo: "",
      DOB: this.state.DOB,
      CountryId: parseInt(0),
      StateId: parseInt(0),
      RoleId: this.state.RoleId,
      Password: "",
      ConfirmPassword: "",
      ModelTitle: "User Information Details",
      ButtonLabel: "Save",
    });
    this.GetCountries();
    this.GetStatesByCountryId(this.props.UserData.CountryId);
  }

  GetCountries = () => {
    //debugger
    var config = {
      method: "get",
      url: process.env.REACT_APP_EVENT_API + `Event/GetCountries`,
      headers: {},
    };

    axios(config)
      .then((response) => {
        let tempCountryList = [];
        response.data.forEach((element) => {
          tempCountryList.push(element);
        });
        this.setState({ CountryList: tempCountryList });
      })
      .catch((error) => {
        if (error.response.status == 401) {
          window.location.pathname = "/logout";
        } else {
          this.setState({
            ErrorMessage: "Error while get all countries",
            SuccessMessage: "",
          });
        }
      });
  };
  GetStatesByCountryId = (id) => {
    if (id == undefined || id == 0) {
      id = 0;
    }
    var config = {
      method: "get",
      url:
        process.env.REACT_APP_EVENT_API + `Event/GetStatesByCountryId?Id=${id}`,
      headers: {},
    };

    axios(config)
      .then((response) => {
        const obj = {
          Id: 0,

          Name: "--Select the State--",

          CountryId: 0,
        };

        const data = response.data;

        data.push(obj);

        this.setState({
          StateList: data,

          CountryId: id,
        });
      })

      .catch((error) => {
        this.setState({
          ErrorMessage: "Error while gettting event get states by countryid",
        });
        if (error.response.status == 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };

  onTagsChangeForCountry = (event, values) => {
    if (values != null) {
      this.setState(
        {
          CountryArray: values,
          StateArray: [],
        },
        () => {
          this.setState({
            StateList: [],
          });
          this.GetStatesByCountryId(this.state.CountryArray.Id);
          if (this.state.CountryArray.Id == 0) {
            this.setState({
              countryCss: 0,
            });
          } else {
            this.setState({
              CountryId: this.state.CountryArray.Id,
              stateedit: false,
              countryCss: 1,
              StateId: 0,
            });
          }
        }
      );
    } else {
      this.setState({
        CountryArray: [],
        StateList: [],
        StateArray: [],
        stateedit: true,

        CountryId: 0,
        StateId: 0,
      });
    }
  };
  onTagsChangeForState = (event, values) => {
    if (values != null) {
      this.setState(
        {
          StateArray: values,
        },
        () => {
          if (this.state.StateArray.Id == 0) {
            this.setState({
              stateCss: 0,
            });
          } else {
            this.setState({
              stateCss: 1,
              StateId: this.state.StateArray.Id,
            });
          }
        }
      );
    } else {
      this.setState({
        StateArray: [],
        StateId: 0,
      });
    }
  };
  onChangeDate = (date) => {
    this.setState({
      DOB: date,
    });
  };
  onChangeDatenew = (date) => {
    this.setState({
      DOB: date,
    });
  };
  handleChange = (e) => {
    this.setState({
      RoleId: e.target.value,
    });
  };
  handleCountryChange = (e) => {
    this.setState({
      StateList: [
        {
          Id: 0,
          ShortName: "NN",
          Name: "--Select the State--",
        },
      ],
    });
    this.GetStatesByCountryId(e.target.value);
    if (e.target.value == 0) {
    } else {
      this.setState({
        StateId: 0,
      });
      this.stateInput.focus();
    }
  };

  handleStateChange = (e) => {
    this.setState({
      StateId: e.target.value,
    });
  };
  onCloseClick = () => {
    //CommonValues.ClearValues();
    window.location.pathname = "/logout";
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onMobileNumberChange = (event) => {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode < 31 && (charCode > 48 || charCode < 57)) {
      return false;
    }

    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleKeypress = (e) => {
    if (e.key == "Enter") {
      this.onSaveClick();
    }
  };

  validate = () => {
    let valid = true;

    if (
      this.state.FirstName == "" ||
      this.state.FirstName == null ||
      this.state.FirstName == undefined
    ) {
      this.setState({
        ErrorMessage: "Please enter first name",
        SuccessMessage: "",
      });
      return false;
    }

    if (
      this.state.LastName == "" ||
      this.state.LastName == null ||
      this.state.LastName == undefined
    ) {
      this.setState({
        ErrorMessage: "Please enter last name",
        SuccessMessage: "",
      });
      return false;
    }

    if (
      this.state.MobileNo == "" &&
      this.state.DOB == "" &&
      this.state.CountryId == "" &&
      this.state.StateId == ""
    ) {
      this.setState({
        ErrorMessage: "Please enter all details",
        SuccessMessage: "",
      });
      return false;
    }
    if (this.state.DOB == "" || this.state.DOB == null) {
      this.setState({
        ErrorMessage: "Please select DOB",
        SuccessMessage: "",
      });
      return false;
    }
    if (moment(this.state.today1).diff(moment(this.state.DOB), "years") < 14) {
      this.setState({
        ErrorMessage: "Age under 14 is not allowed.",
        SuccessMessage: "",
      });
      console.log();
      return false;
    }
    if (this.state.CountryId == 0) {
      this.setState({
        ErrorMessage: "Please select a Country",
        SuccessMessage: "",
      });
      return false;
    }
    if (this.state.StateId == 0) {
      this.setState({
        ErrorMessage: "Please select a state",
        SuccessMessage: "",
      });
      return false;
    }
    if (this.state.MobileNo.length == 0) {
      this.setState({
        ErrorMessage: "Please enter mobile number",
        SuccessMessage: "",
      });
      return false;
    }
    if (this.state.MobileNo.length != 10) {
      this.setState({
        ErrorMessage: "Please enter the valid mobile number",
        SuccessMessage: "",
      });
      return false;
    }
    this.setState({
      ErrorMessage: "",
    });

    return valid;
  };

  onSaveClick = () => {
    if (this.validate()) {
      const newDate = moment(this.state.DOB).format("YYYY-MM-DD");
      var axios = require("axios");
      var data = JSON.stringify({
        FirstName: this.state.FirstName,
        LastName: this.state.LastName,
        UserId: this.props.EditClickUserId,
        MobileNo: this.state.MobileNo,
        DOB: newDate,
        CountryId: parseInt(this.state.CountryId),
        StateId: parseInt(this.state.StateId),
        //CountryId: parseInt(1),
        // StateId: parseInt(1),
      });

      var config = {
        method: "put",
        url: process.env.REACT_APP_API + `User/UserInformation`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      this.setState({
        Loading: true,
      });
      axios(config)
        .then((response) => {
          localStorage.setItem("UserName", this.state.FirstName);

          this.setState({
            Loading: false,
            SuccessMessage: "User information updated successfully..!",
          });
          setTimeout(() => {
            window.location.pathname = "/dashboard";
          }, 2000);
        })
        .catch((error) => {
          if (error.response.status == 409) {
            this.onClick();
            this.setState({
              Loading: false,
              SuccessMessage: "Email address is already exists",
              ErrorMessage: "",
            });
          } else {
            console.log(error);
            this.setState({
              ErrorMessage: "Error while updating users",
              SuccessMessage: "",
            });
          }
          if (error.response.status == 401) {
            window.location.pathname = "/logout";
          } else {
            console.log(error);
          }
        });
    }
  };

  onClick() {
    this.setState({
      displaySuccessMessage: true,
    });
  }

  onHide() {
    this.onCloseClick();
  }

  renderFooter() {
    return (
      <div>
        <Button
          label="OK"
          icon="pi pi-check"
          onClick={() => this.onHide()}
          className="btn myProfile-btn text-13 font-semibold"
        />
      </div>
    );
  }
  renderInput = (props) => (
    <div class="text-left">
      <input
        type="text"
        className="form-control"
        placeholder="Enter DOB"
        onClick={props.onClick}
        value={props.value}
        onChange={props.onChange}
      />
    </div>
  );

  render() {
    const today = new Date();

    return (
      <>
        <Modal.Header className="user-popup-header">
          <Modal.Title className="popup-title">
            {/* <h1>
            {this.state.ModelTitle}</h1> */}
            <h6>You need to fill these mandatory fields to continue.</h6>
          </Modal.Title>
        </Modal.Header>
        <Dialog
          header="Confirmation"
          draggable={false}
          resizable={false}
          visible={this.state.displaySuccessMessage}
          className="small-modal-conf"
          footer={this.renderFooter()}
          onHide={() => this.onHide()}
        >
          <p className="pt-4">{this.state.SuccessMessage}</p>
        </Dialog>
        <Modal.Body>
          <Form className="userevent-flex user-info-details">
            <div class="row mt-3 align-items-center">
              <div class="col-md-4">
                <label for="FirstName" class="col-form-label">
                  First Name <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div class="col-md-8">
                <input
                  className="form-control text-14"
                  type="text"
                  placeholder="Enter First Name"
                  id="FirstName"
                  name="FirstName"
                  onChange={(e) => {
                    const regex = /^[a-zA-Z0-9_]+$/;
                    if (e.target.value === "" || regex.test(e.target.value)) {
                      this.setState({
                        [e.target.name]: e.target.value,
                      });
                    }
                  }}
                  onKeyPress={this.handleKeypress}
                  value={this.state.FirstName}
                  maxLength="12"
                />
              </div>
            </div>

            <div class="row mt-3 align-items-center">
              <div class="col-md-4">
                <label for="LastName" class="col-form-label">
                  Last Name<span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div class="col-md-8">
                <input
                  className="form-control text-14"
                  type="text"
                  placeholder="Enter Last Name"
                  id="LastName"
                  name="LastName"
                  onChange={(e) => {
                    const regex = /^[a-zA-Z0-9_]+$/;
                    if (e.target.value === "" || regex.test(e.target.value)) {
                      this.setState({
                        [e.target.name]: e.target.value,
                      });
                    }
                  }}
                  onKeyPress={this.handleKeypress}
                  value={this.state.LastName}
                  maxLength="12"
                />
              </div>
            </div>
            <div class="row mt-3 align-items-center">
              <div class="col-md-4">
                <label for="DOB" class="col-form-label">
                  DOB <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div class="col-md-8">
                <DatePicker
                  selected={this.state.DOB}
                  onChange={this.onChangeDatenew}
                  className="form-control text-14 input-group-date d-flex"
                  maxDate={today}
                  placeholderText="Enter DOB"
                  yearDropdownItemNumber={115}
                  scrollableYearDropdown={true}
                  scrollableMonthDropdown
                  showYearDropdown
                  showMonthDropdown
                  // scrollableMonthYearDropdown
                />
              </div>
            </div>

            <div class="row mt-3 align-items-center">
              <div class="col-md-4">
                <label for="CountryId" class="col-form-label">
                  Country
                  <span style={{ color: "red" }}> *</span>
                </label>
              </div>
              <div class="col-md-8">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  getOptionLabel={(option) => option.Name}
                  options={this.state.CountryList}
                  onChange={this.onTagsChangeForCountry}
                  sx={{ width: 300 }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{
                        "& > img": { mr: 2, flexShrink: 0 },
                      }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.ShortName.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.ShortName.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.Name}
                    </Box>
                  )}
                  renderInput={(params) =>
                    this.state.CountryArray.Id ? (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                      />
                    ) : (
                      <TextField
                        {...params}
                        label="Choose a country"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )
                  }
                />
              </div>
            </div>

            <div class="row mt-3  align-items-center">
              <div class="col-md-4 ">
                <label className="form-label text-14 font-semibold  ">
                  State
                  <span style={{ color: "red" }}> *</span>
                </label>
              </div>
              <div class="col-md-8">
                <Autocomplete
                  key={new Date()}
                  style={{ width: "100%" }}
                  id="country-select-demo"
                  sx={{ width: 300 }}
                  options={this.state.StateList}
                  autoHighlight
                  openOnFocus
                  autoComplete
                  getOptionLabel={(option) => {
                    if (option.hasOwnProperty("Name")) {
                      return option.Name;
                    }

                    return option;
                  }}
                  onChange={this.onTagsChangeForState}
                  onKeyPress={this.handleKeypress}
                  value={this.state.StateArray.Name}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{
                        "& > img": { mr: 2, flexShrink: 0 },
                      }}
                      {...props}
                    >
                      {option.Name}
                    </Box>
                  )}
                  renderInput={(params) =>
                    this.state.StateArray.Id ? (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,

                          autoComplete: "new-password",
                        }}
                      />
                    ) : (
                      <TextField
                        {...params}
                        label="Choose a state"
                        inputProps={{
                          ...params.inputProps,

                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )
                  }
                />
              </div>
            </div>

            <div class="row mt-3 align-items-center">
              <div class="col-md-4">
                <label for="MobileNo" class="col-form-label">
                  Mobile No <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <div class="col-md-8">
                <input
                  className="form-control text-14"
                  type="text"
                  placeholder="Enter Mobile Number"
                  id="MobileNo"
                  name="MobileNo"
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;

                    if (
                      e.target.value === "" ||
                      (re.test(e.target.value) && e.target.value.length <= 10)
                    ) {
                      this.setState({ [e.target.name]: e.target.value });
                    }
                  }}
                  onKeyPress={this.handleKeypress}
                  value={this.state.MobileNo}
                />
              </div>
            </div>

            {this.state.RoleId == 1 && (
              <div class="row  align-items-center">
                <div class="col-md-4">
                  <label for="RoleId" class="col-form-label">
                    Role <span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <div class="col-md-8">
                  <select
                    className="form-control text-14"
                    value={this.state.RoleId}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeypress}
                  >
                    {this.state.RoleList.map((role) => {
                      return (
                        <option value={role.RoleId}>{role.RoleName}</option>
                      );
                    })}
                  </select>
                </div>
              </div>
            )}

            {this.props.Mode == "C" ? (
              <>
                <div class="row g-3 align-items-center">
                  <div class="col-md-4">
                    <label for="Password" class="col-form-label">
                      Password <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div class="col-md-8">
                    <input
                      className="form-control text-14"
                      type="password"
                      placeholder="Enter Password"
                      id="Password"
                      name="Password"
                      onChange={(e) => {
                        this.setState({
                          [e.target.name]: e.target.value.trim(),
                        });
                      }}
                      onKeyPress={this.handleKeypress}
                      value={this.state.Password}
                    />
                  </div>
                </div>
                &nbsp;
                <div class="row g-3 align-items-center">
                  <div class="col-md-4">
                    <label for="ConfirmPassword" class="col-form-label">
                      Confirm Password <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div class="col-md-8">
                    <input
                      className="form-control text-14"
                      type="password"
                      placeholder="Confirm Password"
                      id="ConfirmPassword"
                      name="ConfirmPassword"
                      onChange={(e) => {
                        this.setState({
                          [e.target.name]: e.target.value.trim(),
                        });
                      }}
                      onKeyPress={this.handleKeypress}
                      value={this.state.ConfirmPassword}
                    />
                  </div>
                </div>
                &nbsp;{" "}
              </>
            ) : null}
          </Form>
        </Modal.Body>
        <Modal.Footer className="userinfo-footer">
          <div className=" ">
            {this.state.ErrorMessage != "" ? (
              <>
                <div
                  class="alert alert-danger d-flex align-items-center usermanagementalertmessage"
                  role="alert"
                >
                  <div className="text-14">
                    <span className="ml-1">{this.state.ErrorMessage}</span>
                  </div>
                </div>
              </>
            ) : null}
            <div>
              <span className="sccuessmessage">
                {this.state.SuccessMessage}
              </span>
            </div>
          </div>
          <div className="btn-section">
            <div>
              <button
                className="btn btn-primary add-event-btn text-13 font-semibold userInfo-save"
                type="submit"
                onClick={this.onSaveClick}
              >
                {this.state.Loading === true ? (
                  <div className="savebtn-loading">
                    <Spinner animation="border" variant="warning" />
                    <b>{this.state.LoadingMessage}</b>
                  </div>
                ) : null}
                {this.state.ButtonLabel}
              </button>
            </div>
            <div class="bd-highlight">
              <Link
                className="btn Create-user-cancle-btn text-13 font-semibold bg-lightblue text-button-grey user-info-cancel"
                to="/logout"
              >
                <span>Close</span>
              </Link>
            </div>
          </div>
        </Modal.Footer>
      </>
    );
  }
}

export default UserInformation;
