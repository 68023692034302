import axios from "axios";
import moment from "moment";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Component, createContext, React } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Footer from "../Common/Footer";
import CommonValues from "../Common/Util";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import FacebookIcon from "./../assets/images/facebook.png";
import LinkedInIcon from "./../assets/images/linkedin.png";
import Logo from "../../src/assets/images/logo/Participiclogo.png";
import TwitterIcon from "./../assets/images/Twitter.png";
import UserIcon from "./../assets/images/user.png";
import EditProfile from "./EditProfile";
import { isAndroid, isIOS } from "react-device-detect";
import { confirmDialog } from "primereact/confirmdialog";
const Image = createContext();
const FirstName = createContext();
class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      NewImageValue:
        "https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg",
      ImageValue:
        "https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg",
      FirstName: "",
      LastName: "",
      Email: "",
      MobileNo: "",
      Description: "",
      ToggleSideBar: true,
      ShowIdentity: false,
      ErrorMessage: "",
      EventDashboardValue: 4,
      sidebarvalue: 5,
      SuccessMessage: "",
      Loading: false,
      UserPhotoImage: false,
      displaySuccessMessage: false,
      buttonLabel: "Save",
      EventList: [],
      showModal: false,
      UserData: {},
      showEditProfileButton: false,
      RoleId: 0,
    };
  }

  onClick() {
    this.setState({
      displaySuccessMessage: true,
    });
  }

  onHide() {
    this.setState({
      displaySuccessMessage: false,
    });
  }

  onViewMoreClick = () => {
    window.location.pathname = "/UserEvent";
  };

  showMore = (EventId) => {
    this.props.history.push(`/AddUserEvent/E/${EventId}`);
  };

  getAllEvents = () => {
    const token = CommonValues.GetAuth0Token().replace(/^"|"$/g, "");
    var config = {
      method: "get",
      url: process.env.REACT_APP_EVENT_API + `Event/GetAllEvents`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    this.setState({
      Loading: true,
    });

    axios(config)
      .then((response) => {
        this.setState({
          EventList: [],
        });
        let counter = 0;
        response.data.forEach((element) => {
          if (counter < 5) {
            this.state.EventList.push({
              Id: element.EventId,
              Name: element.Name,
              Location: element.Location,
              Description: element.Description,
              EventDateTime: moment(element.EventDateTime).format("LLL"),
              Active: "True",
            });
            counter = counter + 1;
          }
        });

        this.setState({
          Loading: false,
        });

        if (response.data.length <= 0) {
          this.setState({
            DataAvailable: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          Loading: false,
          DataAvailable: false,
        });
        if (error.response.status == 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };

  renderFooter() {
    return (
      <div>
        <Button
          label="OK"
          icon="pi pi-check"
          onClick={() => this.onHide()}
          className="btn myProfile-btn text-16 font-semibold"
        />
      </div>
    );
  }

  toogleEditClickPopup = (val) => {
    this.setState({
      showModal: val,
    });
  };

  onCloseClick = (val) => {
    if (val == 1) {
      this.componentDidMount();
    }
    this.setState({
      showModal: false,
    });
  };

  onRefreshClick = () => {
    this.getAllEvents();
  };

  componentDidMount = () => {
    this.getUserDetails();
    this.getAllEvents();

    document.body.classList.remove("login-page");
    document.body.classList.add("dashboard-page");
    const RoleId = CommonValues.GetRoleId();
    const RoleName = CommonValues.GetRoleName();
    this.setState(
      {
        RoleId: RoleId,
      },
      () => {
        if (RoleId == null) {
          this.setState({ RoleId: 2 });
        }
      }
    );
  };

  onSideBarToggle = () => {
    let windowWidth = window.innerWidth;
    if (windowWidth < 767.98) {
      this.setState(
        {
          ToggleSideBarNav: !this.state.ToggleSideBarNav,
        },
        () => {
          localStorage.setItem("sidebar", this.state.ToggleSideBarNav);
          if (this.state.ToggleSideBarNav) {
            document.body.classList.remove("toggleClass");
            document.getElementsByClassName("page-body-wrapper")[0].className =
              "page-body-wrapper";
          } else {
            document.body.classList.add("toggleClass");
            document.getElementsByClassName("page-body-wrapper")[0].className =
              "page-body-wrapper toggleNav";
          }
          this.setState({
            ToggleSideBarNav: !this.state.ToggleSideBarNav,
          });
        }
      );
    } else {
      localStorage.setItem("sidebar", this.state.ToggleSideBarNav);
      if (this.state.ToggleSideBarNav) {
        document.body.classList.remove("toggleClass");
        document.getElementsByClassName("page-body-wrapper")[0].className =
          "page-body-wrapper";
      } else {
        document.body.classList.add("toggleClass");
        document.getElementsByClassName("page-body-wrapper")[0].className =
          "page-body-wrapper toggleNav";
      }
      this.setState({
        ToggleSideBarNav: !this.state.ToggleSideBarNav,
      });
    }
  };
  onSideBarToggle1 = (ToggleSideBarNav) => {
    if (ToggleSideBarNav == 0) {
      localStorage.setItem("sidebar", "false");
    } else {
      localStorage.setItem("sidebar", "true");
    }
    if (ToggleSideBarNav) {
      document.body.classList.remove("toggleClass");
      document.getElementsByClassName("page-body-wrapper")[0].className =
        "page-body-wrapper";
    } else {
      document.body.classList.add("toggleClass");
      document.getElementsByClassName("page-body-wrapper")[0].className =
        "page-body-wrapper toggleNav";
    }
    this.setState({
      ToggleSideBarNav: !ToggleSideBarNav,
    });
  };

  onRefreshClick = () => {
    this.getUserDetails();
  };

  getUserDetails = () => {
    const token = CommonValues.GetAuth0Token().replace(/^"|"$/g, "");
    var config = {
      method: "get",
      url:
        process.env.REACT_APP_API +
        `User/GetUserDetails?id=${CommonValues.GetUserId()}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    this.setState({
      Loading: true,
      buttonLabel: "Getting profile",
    });
    axios(config)
      .then((response) => {
        this.setState(
          {
            FirstName: response.data.FirstName,
            LastName: response.data.LastName,
            Email: response.data.Email,
            MobileNo: response.data.MobileNo,
            ShowIdentity: response.data.ShowIdentity,
            NewImageValue: response.data.ImageURL,
            CreatedDateTime: moment
              .utc(response.data.CreatedDateTime)
              .local()
              .format("LL"),
            Loading: false,
            buttonLabel: "Save",
            UserData: response.data,
          },
          () => {
            this.setState({
              showEditProfileButton: true,
            });
          }
        );
        localStorage.setItem(
          "ProfileImageURL",
          JSON.stringify(response.data.ImageURL)
        );

        this.ContainsAny(response.data.ImageURL, [
          ".jpg",
          ".png",
          ".svg",
          ".jpeg",
          ".JPG",
          ".PNG",
          ".SVG",
          ".JPEG",
          ".avif",
          ".JFIF"
        ]);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };

  ToggleSideBar = () => {
    this.setState({
      ToggleSideBar: !this.state.ToggleSideBar,
    });
  };

  ContainsAny(str, items) {
    for (var i in items) {
      var item = items[i];
      if (str.indexOf(item) > -1) {
        this.setState({
          UserPhotoImage: false,
        });
        return true;
      } else {
        this.setState({
          UserPhotoImage: true,
        });
      }
    }
    return false;
  }
  onDeleteClick = (UserId) => {
    
    var config = {
      method: "put",
      url: process.env.REACT_APP_API + `User/DeleteUser?UserId=${UserId}`,
      headers: {},
    };
    this.setState({
      LoadingMessage: "Delete Event",
      Loading: true,
    });
    axios(config)
      .then((response) => {
        this.setState({
          Loading: false,
          SuccessMessage: "User deleted successfully!!!",
        });
        if (response.status == 200) {
          window.location.pathname = "/logout";
        }

      })
      .catch((error) => {
        if (error.response.status == 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };
  rejectFunc = () => { };
  confirmDelete = (UserData) => {

    confirmDialog({
      message: `Are you sure you want to delete your account? This will remove all of your media and data that you have uploaded.?`,
      header: "Delete Confirmation",
      icon: "pi pi-trash",
      draggable: false,
      resizable: false,
      className: "errorpopup delete-popup",
      accept: () => this.onDeleteClick(UserData.UserId),
      reject: () => this.rejectFunc(),
    });
  };
  render() {
    let spinnerDiv = (
      <div className="card-body" style={{ textAlign: "center" }}>
        <div
          className="d-flex table-loading"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Spinner animation="border" variant="warning" />{" "}
          <b>Loading Profile</b>
        </div>
      </div>
    );
    return (
      <>
        <Toast ref={(el) => (this.toast = el)} />

        <main class="main-content-wrapper">
          <div class="page-body-wrapper">
            <Sidebar
              ToggleSideBar={this.ToggleSideBar}
              sidebarvalue={this.state.sidebarvalue}
            />
            <div class="page-body" style={{ zIndex: 0 }}>
              <div class="row bg-white mobile-navigation align-items-center box-shadow-header pt-3 pb-3 ">
                <div class="toggle-sidebar">
                  <a class="toggle-icon" onClick={this.onSideBarToggle}>
                    <span class="one"></span>
                    <span class="two"></span>
                    <span class="three"></span>
                  </a>
                </div>

                <img
                  src={Logo}
                  width=""
                  height=""
                  alt="Participic"
                  class="img-fluid participic-logo"
                />
              </div>
              <Header
                key={new Date().toString()}
                onToggleButtonClick={this.onToggleButtonClick}
                onSideBarToggle={this.onSideBarToggle}
                EventDashboardValue={this.state.EventDashboardValue}
              />
              <br />
              <div className="container-fluid">
                <div className="">
                  {this.state.Loading === true ? (
                    spinnerDiv
                  ) : (
                    <div class="">
                      <div class="row my-profile mt-4">
                        <div class="col-lg-4 col-md-5 col-12 mb-3">
                          <div class="my-profile-user-info profile h-100">
                            <img
                              src={
                                this.state.UserPhotoImage
                                  ? UserIcon
                                  : this.state.NewImageValue
                              }
                              class="profile-img"
                              alt="User Photo"
                            />
                            <div class="page-title">
                              <h1 class="sec-title">
                                {this.state.FirstName} {this.state.LastName}
                              </h1>
                              <span class="sub-title text-14">
                                <span>
                                  <i className="pi pi-envelope pr-2 iconsize"></i>
                                </span>
                                {this.state.Email}
                                <br />
                                <span>
                                  <i className="pi pi-phone pr-2 iconsize"></i>
                                </span>
                                {this.state.MobileNo}
                              </span>
                              <p class="text-10 text-grey">
                                MEMBER SINCE: {this.state.CreatedDateTime}
                              </p>

                            </div>
                            <div className="profile-btns">
                              <button
                                class="btn btn-primary edit-btn "
                                type="submit"
                                onClick={() => this.toogleEditClickPopup(true)}
                                disabled={
                                  this.state.showEditProfileButton ? false : true
                                }
                              >
                                <span>
                                  <i className="pi pi-user-edit pr-2"></i>
                                </span>
                                Edit Profile
                              </button>

                              {this.state.RoleId == 2 ? (
                                <button
                                  class="btn btn-danger"
                                  type="delete"
                                  style={{ borderRadius: "20px" }}
                                  onClick={() =>
                                    this.confirmDelete(this.state.UserData)
                                  }
                                >

                                  Delete Account
                                </button>
                              ) : null}
                            </div>

                          </div>
                        </div>
                        <div class="col-lg-8 col-md-7 col-12">
                          <div class="row">
                            {this.state.EventList.map((event) => {
                              return (
                                <>
                                  <div class="col-lg-4 col-md-6 card-col mb-3 ">
                                    <div class="card dashboard-card h-100 ico-img">
                                      <div class="card-body">
                                        <div className="outline-delete-button">
                                          <div class="bd-highlight">
                                            <div class="p-2 flex-grow-1 bd-highlight text-14 text-orange font-semibold">
                                              {event.Name}
                                            </div>
                                          </div>
                                        </div>
                                        <div class="d-flex align-items-center bd-highlight dashboard-card-outline pt-2 pb-2">
                                          <div class="p-2 flex-fill bd-highlight">
                                            <div class="recent-event-text font-semibold text-grey">
                                              <ul
                                                class="mb-0"
                                                style={{
                                                  paddingInlineStart: "0px",
                                                  listStyle: "none",
                                                }}
                                              >
                                                <li className="d-flex">
                                                  <span>{event.Location}</span>
                                                  <p class="mb-0">
                                                    {event.EventDateTime}
                                                  </p>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>

                                        <div class="font-semibold text-12 text-black mt-1">
                                          About this Events
                                        </div>
                                        <div class=" font-regular text-10 text-black">
                                          {event.Description.substring(0, 100)}
                                          <label
                                            onClick={() => {
                                              this.showMore(event.Id);
                                            }}
                                          >
                                            <a className="pointer">

                                              Read more
                                            </a>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <br />
                                  </div>
                                </>
                              );
                            })}
                            <div class="col-lg-4 col-md-6  card-col ">
                              <div class="card dashboard-card pt-5 pb-5 bg-lightblue view-more-card">
                                <div class="card-body d-flex align-items-center justify-content-center">
                                  <button
                                    class="btn btn-primary add-user-btn text-11 font-semibold"
                                    type="submit"
                                    onClick={this.onViewMoreClick}
                                  >
                                    View More
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <Footer></Footer>
              <Modal
                show={this.state.showModal}
                dialogClassName="modal-dialog modal-lg modal-dialog-centered"
              >
                <EditProfile
                  onCloseClick={(val) => {
                    this.onCloseClick(val);
                  }}
                  onRefreshClick={this.onRefreshClick}
                  UserData={this.state.UserData}
                />
              </Modal>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default withRouter(MyProfile);
export { Image };
