import React, { Component } from "react";
import moment from "moment";
import { addYears } from "date-fns";
import { date } from "date-fns/locale/af";
class FooterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {

      CurrentDate: moment(new Date()).format("YYYY")
    };
  }
  render() {
    return (
      <div>
        <footer class="footer-bottom" style={{ textAlign: "center" }}>
          <strong>
            Copyright © {this.state.CurrentDate} - {Number(this.state.CurrentDate) + 1} <a href="https://participic.com">Participic</a>.
          </strong>
          All rights reserved.
          <div class="float-right d-none d-sm-inline-block"></div>
        </footer>
      </div>
    );
  }
}

export default FooterPage;  
