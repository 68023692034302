import React, { Component } from 'react'
import CommonValues from '../Common/Util'
import Auth from '../Auth/Auth';
import { Spinner } from 'react-bootstrap';
export default class Logout extends Component {
  componentDidMount(){
    CommonValues.ClearValues();
    Auth.logout();
  }
  render() {
    return (
      <>
              <main class="main-content">
        <div className="table-loading"
             style={{
               position: "fixed",
               top: "50%",
               left: "50%",
               transform: "translate(-50%, -50%)",
               transform: "translate(-50%, -50%)",
               fontFamily: "primeicon",
               fontSize:"16px",
               fontWeight:"700px",
               color:"black",
             }}
           >
             <Spinner animation="border" variant="primary" />           <b> Logging out</b>
           </div>
           </main>
      </>
    );
  }
}
